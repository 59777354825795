<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="handleSideBarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="handleSideBarOpen"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 ring-1 ring-white/10 pt-2"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-12 w-auto"
                    src="~assets/img/the_tax_ghost_suit.gif"
                    alt="Your Company"
                  />
                </div>
                <ClientOnly>
                  <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" class="-mx-2 space-y-1">
                          <li v-for="item in navigationItems" :key="item.name">
                            <NuxtLink
                              :to="item.href"
                              :class="[
                                navItemActive(item)
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:text-white hover:bg-gray-800',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                              ]"
                              @click="handleNavClick()"
                            >
                              <component
                                :is="item.icon"
                                class="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {{ item.name }}
                            </NuxtLink>
                          </li>
                        </ul>
                      </li>
                      <li class="mt-auto">
                        <NuxtLink
                          :to="userSettingsRoute"
                          class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                        >
                          <Cog6ToothIcon
                            class="h-6 w-6 shrink-0"
                            aria-hidden="true"
                          />
                          Settings
                        </NuxtLink>
                      </li>
                    </ul>
                  </nav>
                </ClientOnly>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-4 pt-2"
      >
        <div class="flex h-16 shrink-0 items-center">
          <img
            class="h-12 w-auto"
            src="~assets/img/the_tax_ghost_suit.gif"
            alt="Your Company"
          />
        </div>
        <ClientOnly>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-1">
                  <li v-for="item in navigationItems" :key="item.name">
                    <NuxtLink
                      :to="item.href"
                      :class="[
                        navItemActive(item)
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-400 hover:text-white hover:bg-gray-800',
                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                      ]"
                      @click="handleNavClick()"
                    >
                      <component
                        :is="item.icon"
                        class="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      {{ item.name }}
                    </NuxtLink>
                  </li>
                </ul>
              </li>
              <li v-show="showSettings" class="mt-auto">
                <NuxtLink
                  href="#"
                  class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-400 hover:bg-gray-800 hover:text-white"
                  :to="userSettingsRoute"
                >
                  <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
                  Settings
                </NuxtLink>
              </li>
            </ul>
          </nav>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import { Cog6ToothIcon, XMarkIcon } from "@heroicons/vue/24/outline";

interface NavigationItem {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
  current: boolean;
}

const props = defineProps<{
  navigationItems: NavigationItem[];
  userSettingsRoute: string;
  sidebarOpen: boolean;
  handleSideBarOpen: () => void;
  showSettings: boolean;
}>();

const emit = defineEmits(["closeSidebar"]);

const route = useRoute();

const navItemActive = (item: NavigationItem) => {
  return item.href === route.path;
};

const handleNavClick = () => {
  emit("closeSidebar");
};

const handleSideBarOpen = () => {
  props.handleSideBarOpen();
};
</script>

<style scoped></style>
